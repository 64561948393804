import { createRoot } from 'react-dom/client';

import { CognitoAuthRouter } from './aws-cognito';
import { MakoThemeProvider } from './MakoThemeProvider';

export const App = () => {
  return (
    <MakoThemeProvider>
      <CognitoAuthRouter />
    </MakoThemeProvider>
  );
};

const rootNode = document.getElementById('root');
if (rootNode) {
  const root = createRoot(rootNode);
  root.render(<App />);
}
