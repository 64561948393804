import { PropsWithChildren } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button, Link, Paper, Stack, Typography } from '@mui/material';
import { ISignUpResult } from 'amazon-cognito-identity-js';
import { FormCheckbox } from 'mako-forms';
import { useTheme } from 'mako-theme';
import * as z from 'zod';

import { useCognito } from '../hooks';
import { FacebookSignInButton, GoogleSignInButton, TextField } from '../ui';

const { object, string, boolean } = z;
const schema = object({
  email: string().email().nonempty(),
  password: string().min(8).nonempty(),
  termsAndConditions: boolean(),
});

type FormValues = z.infer<typeof schema>;

const useSignUpForm = (
  onSuccess: ({
    result,
    email,
  }: {
    result: ISignUpResult;
    email: string;
  }) => void,
  onFailure: (error: unknown) => void,
) => {
  const { signUp } = useCognito();
  const formBag = useForm<FormValues>({
    resolver: zodResolver(schema),
    reValidateMode: 'onBlur',
    mode: 'onBlur',
  });

  return {
    formBag,
    onSubmit: formBag.handleSubmit(async (values) => {
      if (values.termsAndConditions) {
        try {
          const result = await signUp(values.email, values.password);
          onSuccess({ result, email: values.email });
        } catch (error) {
          onFailure(error);
        }
      }
    }),
  };
};

const SignUpButton = ({ disabled }: { disabled: boolean }) => {
  return (
    <Button
      variant="contained"
      fullWidth
      size="large"
      type="submit"
      disabled={disabled}
    >
      Create Account
    </Button>
  );
};

const SignInButton = () => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{ width: '100%' }}
    >
      <Typography variant="body2" textAlign="center">
        Already have an account?
      </Typography>
      <Button variant="text" LinkComponent={Link} href="/auth/sign-in">
        Sign In
      </Button>
    </Box>
  );
};

const FormLayout = (props: PropsWithChildren) => {
  return (
    <Paper
      sx={({ spacing }) => ({
        maxWidth: '442px',
        margin: '102px auto',
        padding: spacing(10),
      })}
    >
      <Typography variant="h5" textAlign="center">
        Create an account
      </Typography>
      <Box mt="24px">{props.children}</Box>
    </Paper>
  );
};

type SignUpFormProps = {
  onSuccess: ({
    result,
    email,
  }: {
    result: ISignUpResult;
    email: string;
  }) => void;
  onError: (error: unknown) => void;
};

export const SignUpForm = (props: SignUpFormProps) => {
  const { signInWithFacebook, signInWithGoogle } = useCognito();
  const { palette } = useTheme();
  const { formBag, onSubmit } = useSignUpForm(props.onSuccess, props.onError);

  return (
    <FormLayout>
      <FormProvider {...formBag}>
        <form onSubmit={onSubmit}>
          <Stack spacing={3}>
            <TextField name="email" label="Email" type="email" />
            <TextField
              name="password"
              label="Password"
              type="password"
              helperText="Password should have 8+ characters"
            />
            <FormCheckbox
              name="termsAndConditions"
              label={
                <Typography variant="body2">
                  I agree to{' '}
                  <Link color={palette.common.black} fontWeight="600" href="/">
                    Terms & Conditions
                  </Link>{' '}
                  and{' '}
                  <Link color={palette.common.black} fontWeight="600" href="/">
                    Privacy Policy
                  </Link>
                </Typography>
              }
            />
            <SignUpButton disabled={!formBag.formState.isValid} />
            <SignInButton />
            <Typography
              variant="body2"
              textAlign="center"
              color={palette.grey[600]}
            >
              or
            </Typography>
            <GoogleSignInButton onClick={signInWithGoogle} />
            <FacebookSignInButton onClick={signInWithFacebook} />
          </Stack>
        </form>
      </FormProvider>
    </FormLayout>
  );
};
