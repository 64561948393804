import React from 'react';
import { Typography } from '@mui/material';
import { FormTextField, FormTextFieldProps } from 'mako-forms';

export const TextField = ({ label, ...props }: FormTextFieldProps) => {
  return (
    <>
      <Typography variant="caption">{label}</Typography>
      <FormTextField
        {...props}
        label={null}
        FormHelperTextProps={{
          style: {
            marginRight: 0,
            marginLeft: 0,
          },
        }}
      />
    </>
  );
};
