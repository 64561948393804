import { useEffect, useRef } from 'react';

const useInterval = (callback: () => void) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const savedCallback = useRef<any>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    const id = setInterval(tick, 1000);

    return () => clearInterval(id);
  }, []);
};

export default useInterval;
