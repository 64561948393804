import { useState } from 'react';
import { useNavigate } from 'react-router';
import { Paper } from '@mui/material';
import { ISignUpResult } from 'amazon-cognito-identity-js';

import {
  AmplifyError,
  USER_NOT_CONFIRMED_EXCEPTION,
} from '../configureAmplify';
import { useCognito } from '../hooks';
import { SignInForm } from './SignInForm';

export const SignIn = () => {
  const [user, setUser] = useState<ISignUpResult | null>(null);
  const navigate = useNavigate();
  const { resendSignUp } = useCognito();

  const handleSuccess = (result: ISignUpResult) => {
    setUser(result);
  };

  const handleAmplifyError = async ({
    error,
    email,
  }: {
    error: AmplifyError;
    email: string;
  }) => {
    if (error.code === USER_NOT_CONFIRMED_EXCEPTION) {
      await resendSignUp(email);

      const encodedEmail = encodeURIComponent(email);
      navigate(`/auth/sign-up-confirm?email=${encodedEmail}`);
    }
  };

  return user ? (
    <Paper>Successfully logged in</Paper>
  ) : (
    <SignInForm onSuccess={handleSuccess} onAmplifyError={handleAmplifyError} />
  );
};
