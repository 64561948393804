/// <reference types="vite/client" />

import { useEffect, useLayoutEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useCookie } from 'react-use';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { Auth, Hub } from 'aws-amplify';
import { CognitoUser } from 'mako-cognito-adapter';
import { createContainer } from 'unstated-next';

import { AmplifyAuthConfig, configureAmplify } from '../configureAmplify';

function useTemplate(config?: AmplifyAuthConfig) {
  const [user, setUser] = useState<CognitoUser | null>(null);
  const [, setCookie] = useCookie('id_token');
  const [, setAccessTokenCookie] = useCookie('access_token');
  const [, setRefreshTokenCookie] = useCookie('refresh_token');
  const [searchParams] = useSearchParams();

  const signInWithFacebook = async () => {
    try {
      await Auth.federatedSignIn({
        provider: CognitoHostedUIIdentityProvider.Facebook,
      });
    } catch (e) {
      console.error(e);
    }
  };

  const signInWithGoogle = async () => {
    try {
      await Auth.federatedSignIn({
        provider: CognitoHostedUIIdentityProvider.Google,
      });
    } catch (e) {
      console.error(e);
    }
  };

  useLayoutEffect(() => {
    if (config) configureAmplify(config);
  }, []);

  useEffect(() => {
    Hub.listen('auth', (data) => {
      if (data.payload.event === 'signIn') {
        setUser(data.payload.data);
      }
    });
  }, []);

  useEffect(() => {
    const redirectUrl =
      searchParams.get('redirect_url') ||
      import.meta.env.VITE_COGNITO_DEFAULT_REDIRECT_URL;

    if (user && redirectUrl) {
      const token = user.signInUserSession.idToken.jwtToken;
      const accessToken = user.signInUserSession.accessToken.jwtToken;
      const refreshToken = user.signInUserSession.refreshToken.token;

      setCookie(token, {
        domain: import.meta.env.VITE_COGNITO_COOKIES_SUBDOMAIN,
      });
      setAccessTokenCookie(accessToken, {
        domain: import.meta.env.VITE_COGNITO_COOKIES_SUBDOMAIN,
      });

      setRefreshTokenCookie(refreshToken, {
        domain: import.meta.env.VITE_COGNITO_COOKIES_SUBDOMAIN,
      });
      window.location.href = redirectUrl;
    }
  }, [user]);

  return {
    user,
    setUser,
    signIn: Auth.signIn.bind(Auth),
    signUp: Auth.signUp.bind(Auth),
    confirmSignUp: Auth.confirmSignUp.bind(Auth),
    resendSignUp: Auth.resendSignUp.bind(Auth),
    forgotPassword: Auth.forgotPassword.bind(Auth),
    forgotPasswordSubmit: Auth.forgotPasswordSubmit.bind(Auth),
    federatedSignIn: Auth.federatedSignIn.bind(Auth),
    currentAuthenticatedUser: Auth.currentAuthenticatedUser.bind(Auth),
    currentSession: Auth.currentSession.bind(Auth),
    signInWithFacebook,
    signInWithGoogle,
  };
}

const CognitoContext = createContainer(useTemplate);
export const CognitoProvider = CognitoContext.Provider;
export const useCognito: () => {
  user: CognitoUser | null;
  setUser: React.Dispatch<React.SetStateAction<CognitoUser | null>>;
  signIn: typeof Auth.signIn;
  signUp: typeof Auth.signUp;
  resendSignUp: typeof Auth.resendSignUp;
  confirmSignUp: typeof Auth.confirmSignUp;
  forgotPassword: typeof Auth.forgotPassword;
  forgotPasswordSubmit: typeof Auth.forgotPasswordSubmit;
  federatedSignIn: typeof Auth.federatedSignIn;
  currentAuthenticatedUser: typeof Auth.currentAuthenticatedUser;
  currentSession: typeof Auth.currentSession;
  signInWithFacebook: () => void;
  signInWithGoogle: () => void;
} = CognitoContext.useContainer;
