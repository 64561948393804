import { Amplify } from 'aws-amplify';

export type AmplifyAuthConfig = {
  identityPoolId?: string;
  region: string;
  userPoolId: string;
  userPoolWebClientId: string;
  oAuthDomain: string;
  redirectSignIn: string;
  redirectSignOut: string;
};

export type AmplifyError = {
  code: string;
  name: string;
  message: string;
  stack: string;
};

export const REDIRECT_SIGN_IN_URL = 'http://localhost:8000/cognito/signin';
export const OAUTH_DOMAIN =
  'mako-authentication.auth.eu-central-1.amazoncognito.com';

export function redirectSignInManually() {
  window.open(
    `https://${
      import.meta.env.VITE_COGNITO_OAUTH_DOMAIN
    }/login?response_type=code&client_id=${
      import.meta.env.VITE_COGNITO_USER_POOL_WEB_CLIENT_ID
    }&redirect_uri=${import.meta.env.VITE_COGNITO_REDIRECT_SIGN_IN_URL}`,
    '_self',
  );
}

export function configureAmplify(authConfig: AmplifyAuthConfig) {
  const { oAuthDomain, redirectSignIn, redirectSignOut, ...config } =
    authConfig;
  Amplify.configure({
    Auth: {
      ...config,
      mandatorySignIn: true,
      oauth: {
        domain: oAuthDomain,
        scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
        responseType: 'code',
        redirectSignIn,
        redirectSignOut,
        options: { AdvancedSecurityDataCollectionFlag: true },
      },
      federationTarget: 'COGNITO_USER_POOLS',
    },
  });
}

export const USER_NOT_CONFIRMED_EXCEPTION = 'UserNotConfirmedException';
export const CODE_MISMATCH_EXCEPTION = 'CodeMismatchException';

const exceptions = [USER_NOT_CONFIRMED_EXCEPTION, CODE_MISMATCH_EXCEPTION];

export function isAmplifyError(error: any) {
  return error?.code && exceptions.includes(error.code);
}
