import { PropsWithChildren } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Box,
  Button,
  Link,
  Paper,
  Stack,
  SxProps,
  Typography,
  useTheme,
} from '@mui/material';
import { ISignUpResult } from 'amazon-cognito-identity-js';
import * as zod from 'zod';

import { AmplifyError, isAmplifyError } from '../configureAmplify';
import { useCognito } from '../hooks';
import { FacebookSignInButton, GoogleSignInButton, TextField } from '../ui';

type SignInFormProps = {
  onSuccess: (result: ISignUpResult) => void;
  onAmplifyError: ({
    error,
    email,
  }: {
    error: AmplifyError;
    email: string;
  }) => void;
  onError?: ({ error }: { error: unknown }) => void;
  portalName?: string | 'MAKO';
};

const schema = zod.object({
  email: zod.string().email(),
  password: zod.string(),
});

type FormValues = zod.infer<typeof schema>;

const useSignInForm = (
  onSuccess: (result: ISignUpResult) => void,
  onAmplifyFailure: ({
    error,
    email,
  }: {
    error: AmplifyError;
    email: string;
  }) => void,
  onFailure?: ({ error }: { error: unknown }) => void,
) => {
  const { signIn } = useCognito();
  const formBag = useForm<FormValues>({
    resolver: zodResolver(schema),
    reValidateMode: 'onBlur',
    mode: 'onBlur',
  });

  return {
    formBag,
    onSubmit: formBag.handleSubmit(async (values) => {
      try {
        const result = await signIn(values.email, values.password);
        onSuccess(result);
      } catch (error) {
        if (isAmplifyError(error)) {
          onAmplifyFailure({
            error: error as AmplifyError,
            email: values.email,
          });
        } else {
          onFailure?.({ error });
        }
      }
    }),
  };
};

const SignInButton = () => {
  return (
    <Button sx={{ mt: 6 }} variant="contained" fullWidth type="submit">
      Login
    </Button>
  );
};

const SignUpButton = () => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{ mt: 6 }}
    >
      <Typography variant="body2">Don't have an account?</Typography>
      <Button variant="text" LinkComponent={Link} href="/auth/sign-up">
        Register
      </Button>
    </Box>
  );
};

const Form = (props: SignInFormProps) => {
  const { signInWithFacebook, signInWithGoogle } = useCognito();

  const { palette } = useTheme();

  const { formBag, onSubmit } = useSignInForm(
    props.onSuccess,
    props.onAmplifyError,
    props.onError,
  );

  return (
    <form onSubmit={onSubmit}>
      <FormProvider {...formBag}>
        <Stack spacing={3}>
          <TextField
            name="email"
            label="Email"
            type="email"
            variant="outlined"
          />
          <TextField name="password" label="Password" type="password" />
          <Box display="flex" justifyContent="flex-end">
            <Button<typeof Link>
              variant="text"
              component={Link}
              href="/auth/forgot-password"
            >
              Forgot Password?
            </Button>
          </Box>
          <SignInButton />
          <SignUpButton />
          <Typography
            variant="body2"
            textAlign="center"
            color={palette.grey[600]}
          >
            or
          </Typography>
          <GoogleSignInButton onClick={signInWithGoogle} />
          <FacebookSignInButton onClick={signInWithFacebook} />
        </Stack>
      </FormProvider>
    </form>
  );
};

const FormLayout = (
  props: PropsWithChildren<Pick<SignInFormProps, 'portalName'>>,
) => {
  const { spacing } = useTheme();
  const paperStyles: SxProps = {
    width: '442px',
    height: '660px',
    padding: spacing(10),
    mx: 'auto',
  };

  const wrapperStyles: SxProps = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100vh',
    minHeight: '1000px',
    gap: spacing(2),
  };

  const titleStyles: SxProps = {
    mb: spacing(4),
  };

  return (
    <Box sx={wrapperStyles}>
      <Paper sx={paperStyles} elevation={2}>
        <Typography variant="h5" textAlign="center" sx={titleStyles}>
          Login to {props?.portalName}
        </Typography>
        <Box>{props.children}</Box>
      </Paper>
    </Box>
  );
};

export const SignInForm = (props: SignInFormProps) => {
  return (
    <FormLayout portalName={props.portalName ?? 'Mako'}>
      <Form {...props} />
    </FormLayout>
  );
};
