import { PropsWithChildren } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ArrowBackIos as BackIcon } from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  Link,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import * as z from 'zod';

import { AmplifyError, isAmplifyError } from '../configureAmplify';
import { useCognito } from '../hooks';
import { TextField } from '../ui';

const { object, string } = z;
const schema = object({
  username: string({ required_error: 'This field is required' }).email(),
});

type FormValues = z.infer<typeof schema>;

const useForgotPasswordForm = (
  onSuccess: (username: string) => void,
  onAmplifyFailure: ({ error }: { error: AmplifyError }) => void,
  onFailure?: (error: unknown) => void,
) => {
  const { forgotPassword } = useCognito();
  const formBag = useForm<FormValues>({
    resolver: zodResolver(schema),
    reValidateMode: 'onBlur',
    mode: 'onBlur',
  });

  return {
    formBag,
    onSubmit: formBag.handleSubmit(async (values) => {
      try {
        await forgotPassword(values.username);
        onSuccess(values.username);
      } catch (error) {
        if (isAmplifyError(error)) {
          onAmplifyFailure({ error: error as AmplifyError });
        }
        onFailure?.(error);
      }
    }),
  };
};

const SubmitButton = () => {
  return (
    <Button variant="contained" fullWidth size="large" type="submit">
      Submit
    </Button>
  );
};

const BackButton = ({ href }: { href: string }) => (
  <IconButton
    LinkComponent={Link}
    href={href}
    sx={{ justifyContent: 'center' }}
  >
    <BackIcon
      color="primary"
      sx={({ spacing }) => ({ marginLeft: spacing(0.5) })}
    />
  </IconButton>
);

const FormLayout = (props: PropsWithChildren) => {
  return (
    <Paper
      sx={({ spacing }) => ({
        maxWidth: '442px',
        margin: '102px auto',
        padding: spacing(5),
      })}
    >
      <Box display="flex" justifyContent="center">
        <Box flex={1} justifyContent="center">
          <BackButton href="/auth/sign-in/" />
        </Box>
        <Typography
          variant="h5"
          textAlign="center"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          Reset your password
        </Typography>
        <Box flex={1} />
      </Box>
      <Typography
        variant="body1"
        textAlign="center"
        sx={({ spacing }) => ({ marginTop: spacing(2) })}
      >
        Provide your email address
      </Typography>
      <Box sx={({ spacing }) => ({ marginTop: spacing(3) })}>
        {props.children}
      </Box>
    </Paper>
  );
};

type ForgotPasswordFormProps = {
  onSuccess: (username: string) => void;
  onError: (error: unknown) => void;
};

export const ForgotPasswordForm = (props: ForgotPasswordFormProps) => {
  const { formBag, onSubmit } = useForgotPasswordForm(
    props.onSuccess,
    props.onError,
  );

  return (
    <FormLayout>
      <FormProvider {...formBag}>
        <form onSubmit={onSubmit}>
          <Stack spacing={4}>
            <TextField name="username" label="Email" type="email" />
            <SubmitButton />
          </Stack>
        </form>
      </FormProvider>
    </FormLayout>
  );
};
