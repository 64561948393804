import { useNavigate } from 'react-router';

import { ForgotPasswordForm } from './ForgotPasswordForm';

export const ForgotPassword = () => {
  const navigate = useNavigate();

  return (
    <ForgotPasswordForm
      onSuccess={(email: string) => {
        const encodedEmail = encodeURIComponent(email);
        navigate(`/auth/change-password?email=${encodedEmail}`);
      }}
      onError={(error) => {
        console.error(error);
      }}
    />
  );
};
