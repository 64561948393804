import {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from 'react';
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import { CssBaseline, Theme, ThemeProvider } from '@mui/material';
import { createTheme, PalletsColors } from 'mako-theme';

type MakoThemeOptions = {
  primaryColor?: PalletsColors;
  fontFamily?: string;
};

const Context = createContext<{
  setMakoOptions: (options: MakoThemeOptions) => void;
  resetOptions: () => void;
  makoOptions: MakoThemeOptions;
} | null>(null);

export const useMakoThemeOptions = () => {
  const context = useContext(Context);

  if (context === null) {
    throw new Error('Error');
  }

  return context;
};

export const MakoThemeProvider = (props: PropsWithChildren) => {
  const [makoOptions, setMakoOptions] = useState<MakoThemeOptions>({});

  const makoTheme: Theme = useMemo(() => {
    const theme = createTheme(makoOptions ?? {});

    return theme;
  }, [makoOptions]);

  return (
    <Context.Provider
      value={{
        setMakoOptions: (options) =>
          setMakoOptions((opt) => ({ ...opt, ...options })),
        makoOptions,
        resetOptions: () => {
          setMakoOptions({});
        },
      }}
    >
      <EmotionThemeProvider theme={makoTheme}>
        <ThemeProvider theme={makoTheme}>
          <CssBaseline />
          {props.children}
        </ThemeProvider>
      </EmotionThemeProvider>
    </Context.Provider>
  );
};
