import { useCallback, useState } from 'react';

import { useCognito } from '..';
import useInterval from './useInterval';

export const useCodeResend = ({ email }: { email: string }) => {
  const { resendSignUp } = useCognito();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_counter, setCounter] = useState<number>(0);
  const [codeResend, setCodeResend] = useState(false);
  useInterval(() => {
    setCounter((seconds: number) => {
      if (seconds > 0) {
        return seconds - 1;
      } else {
        setCodeResend(false);

        return 0;
      }
    });
  });

  const handleResend = useCallback(() => {
    setCodeResend(true);
    resendSignUp(email);
    setCounter(10);
  }, [codeResend, email]);

  return {
    handleResend,
    codeResend,
  };
};
