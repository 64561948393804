import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Hub } from 'aws-amplify';

import { redirectSignInManually } from '../configureAmplify';
import { useCognito } from '../hooks';

export const CognitoSignIn = () => {
  const navigate = useNavigate();

  const { currentAuthenticatedUser, setUser } = useCognito();

  const updateUserData = async () => {
    try {
      const userData = await currentAuthenticatedUser();
      const userId = userData?.signInUserSession?.idToken?.payload?.sub;

      if (userId) {
        setUser(userData);
        navigate('/auth/sign-in');
      } else {
        setUser(null);
      }
    } catch (e) {
      setUser(null);
      redirectSignInManually();
    }
  };

  useEffect(() => {
    try {
      Hub.listen('auth', async ({ payload: { event, data } }) => {
        switch (event) {
          case 'signIn':
            await updateUserData();
            break;
          case 'cognitoHostedUI':
            await updateUserData();
            break;
          case 'cognitoHostedUI_failure':
            console.error('Sign in failure', data);
            navigate('/auth/sign-in');
            break;
          case 'signIn_failure': {
            const errorDescription = decodeURIComponent(
              data.message,
            ).replaceAll('+', ' ');

            console.log(errorDescription);
            navigate('/auth/sign-in');

            break;
          }
        }
      });

      updateUserData();
    } catch (e) {
      navigate('/auth/sign-in');
      console.error(e);
    }
  }, []);

  return null;
};
