import { useNavigate } from 'react-router';

import { AmplifyError } from '../configureAmplify';
import { ChangePasswordForm } from './ChangePasswordForm';

export const ChangePassword = () => {
  const navigate = useNavigate();

  return (
    <ChangePasswordForm
      onSuccess={() => {
        navigate('/auth/sign-in');
      }}
      onAmplifyError={({ error }: { error: AmplifyError }) => {
        console.error(error);
      }}
      onError={(error) => {
        console.error(error);
      }}
    />
  );
};
