import {
  createBrowserRouter,
  Outlet,
  RouteObject,
  RouterProvider,
} from 'react-router-dom';

import { CognitoSignIn } from './CogintoSignIn';
import { AmplifyAuthConfig } from './configureAmplify';
import { ChangePassword, ForgotPassword } from './ForgotPassword';
import { CognitoProvider } from './hooks/useCognitoAuthentication';
import { SignIn } from './SignIn';
import { SignUp } from './SignUp';
import { SignUpConfirm } from './SignUpConfirm';

const WithProvider = (config: AmplifyAuthConfig) => {
  return (
    <CognitoProvider initialState={config}>
      <Outlet />
    </CognitoProvider>
  );
};

export const cognitoAuthenticationRoute: (
  config: AmplifyAuthConfig,
) => RouteObject[] = (config) => [
  {
    path: '/',
    element: <WithProvider {...config} />,
    children: [
      {
        path: '/',
        element: <SignIn />,
      },
      {
        path: '/auth/sign-up',
        element: <SignUp />,
      },
      {
        path: '/auth/sign-up-confirm',
        element: <SignUpConfirm />,
      },
      {
        path: '/auth/sign-in',
        element: <SignIn />,
      },
      {
        path: '/auth/forgot-password',
        element: <ForgotPassword />,
      },
      {
        path: '/auth/change-password',
        element: <ChangePassword />,
      },
      {
        path: '/cognito/signin',
        element: <CognitoSignIn />,
      },
    ],
  },
];

const router = createBrowserRouter(
  cognitoAuthenticationRoute({
    region: import.meta.env.VITE_COGNITO_REGION,
    userPoolId: import.meta.env.VITE_COGNITO_USER_POOL_ID,
    userPoolWebClientId: import.meta.env.VITE_COGNITO_USER_POOL_WEB_CLIENT_ID,
    identityPoolId: import.meta.env.VITE_COGNITO_IDENTITY_POOL_ID,
    oAuthDomain: import.meta.env.VITE_COGNITO_OAUTH_DOMAIN,
    redirectSignIn: import.meta.env.VITE_COGNITO_REDIRECT_SIGN_IN_URL,
    redirectSignOut: import.meta.env.VITE_COGNITO_REDIRECT_SIGN_OUT_URL,
  }),
);

export const CognitoAuthRouter = () => {
  return <RouterProvider router={router} />;
};
