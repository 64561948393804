import { useNavigate } from 'react-router';

import { SignUpConfirmForm } from './SignUpConfirmForm';

export const SignUpConfirm = () => {
  const navigate = useNavigate();

  return (
    <SignUpConfirmForm
      onSuccess={() => {
        navigate('/auth/sign-in');
      }}
      onError={(error) => {
        console.error(error);
      }}
    />
  );
};
