import { PropsWithChildren } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation } from 'react-use';
import { zodResolver } from '@hookform/resolvers/zod';
import { ArrowBackIos as BackIcon } from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  Link,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { ISignUpResult } from 'amazon-cognito-identity-js';
import { FormTextField } from 'mako-forms';
import * as z from 'zod';

import { useCodeResend, useCognito } from '../hooks';

const { object, string } = z;
const schema = object({
  code: string().min(6),
});

type FormValues = z.infer<typeof schema>;

const useSignUpConfirmForm = ({
  onSuccess,
  onFailure,
  email,
}: {
  onSuccess: (result: ISignUpResult) => void;
  onFailure: (error: unknown) => void;
  email: string;
}) => {
  const { confirmSignUp } = useCognito();
  const formBag = useForm<FormValues>({
    resolver: zodResolver(schema),
    reValidateMode: 'onBlur',
    mode: 'onBlur',
  });

  return {
    formBag,
    onSubmit: formBag.handleSubmit(async (values) => {
      try {
        const result = await confirmSignUp(email, values.code);
        onSuccess(result);
      } catch (error) {
        onFailure(error);
      }
    }),
  };
};

const ConfirmButton = () => {
  return (
    <Button variant="contained" fullWidth size="large" type="submit">
      Confirm
    </Button>
  );
};

const BackButton = ({ href }: { href: string }) => (
  <IconButton
    LinkComponent={Link}
    href={href}
    sx={{ justifyContent: 'center' }}
  >
    <BackIcon
      color="primary"
      sx={({ spacing }) => ({ marginLeft: spacing(0.5) })}
    />
  </IconButton>
);

const CodeResendButton = ({
  onResend,
  codeResend,
}: {
  onResend: () => void;
  codeResend: boolean;
}) =>
  codeResend ? (
    <Typography
      variant="body2"
      sx={({ palette }) => ({ color: palette.success.main })}
    >
      Verification code has been resent to your email
    </Typography>
  ) : (
    <Typography
      onClick={onResend}
      variant="body2"
      sx={({ palette }) => ({ cursor: 'pointer', color: palette.primary.main })}
    >
      Resend the code
    </Typography>
  );

const FormLayout = (props: PropsWithChildren) => {
  return (
    <Paper sx={{ maxWidth: '442px', margin: '102px auto', padding: '40px' }}>
      <Box display="flex" justifyContent="center">
        <Box flex={1} justifyContent="center">
          <BackButton href="/auth/sign-in" />
        </Box>
        <Typography
          variant="h5"
          textAlign="center"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          Confirmation code
        </Typography>
        <Box flex={1} />
      </Box>
      <Box mt="24px">{props.children}</Box>
    </Paper>
  );
};

type SignUpFormProps = {
  onSuccess: (result: ISignUpResult) => void;
  onError: (error: unknown) => void;
};

export const SignUpConfirmForm = (props: SignUpFormProps) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = decodeURIComponent(queryParams.get('email')!);

  const { codeResend, handleResend } = useCodeResend({ email });

  const { formBag, onSubmit } = useSignUpConfirmForm({
    onSuccess: props.onSuccess,
    onFailure: props.onError,
    email: email,
  });

  return (
    <FormLayout>
      <FormProvider {...formBag}>
        <form onSubmit={onSubmit}>
          <Stack spacing={2}>
            <FormTextField name="code" label="Code" type="text" />
            <CodeResendButton onResend={handleResend} codeResend={codeResend} />
            <ConfirmButton />
          </Stack>
        </form>
      </FormProvider>
    </FormLayout>
  );
};
