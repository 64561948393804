import { PropsWithChildren } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { ArrowBackIos as BackIcon } from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  Link,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import * as z from 'zod';

import { AmplifyError, isAmplifyError } from '../configureAmplify';
import { useCodeResend, useCognito } from '../hooks';

const { object, string } = z;
const schema = object({
  password: string({ required_error: 'This field is required' }),
  code: string({ required_error: 'This field is required' }),
});

type FormValues = z.infer<typeof schema>;

const useForgotPasswordForm = ({
  onSuccess,
  onFailure,
  onAmplifyFailure,
  email,
}: {
  onSuccess: (result: unknown) => void;
  onAmplifyFailure: ({ error }: { error: AmplifyError }) => void;
  onFailure?: (error: unknown) => void;
  email: string;
}) => {
  const { forgotPasswordSubmit } = useCognito();
  const formBag = useForm<FormValues>({
    resolver: zodResolver(schema),
    reValidateMode: 'onBlur',
    mode: 'onBlur',
  });

  return {
    formBag,
    onSubmit: formBag.handleSubmit(async (values) => {
      try {
        const result = await forgotPasswordSubmit(
          email,
          values.code,
          values.password,
        );
        onSuccess(result);
      } catch (error) {
        if (isAmplifyError(error)) {
          onAmplifyFailure({ error: error as AmplifyError });
        } else {
          onFailure?.(error);
        }
      }
    }),
  };
};

const CodeResendButton = ({
  onResend,
  codeResend,
}: {
  onResend: () => void;
  codeResend: boolean;
}) =>
  codeResend ? (
    <Typography
      variant="body2"
      sx={({ palette }) => ({ color: palette.success.main })}
    >
      Verification code has been resent to your email
    </Typography>
  ) : (
    <Typography
      onClick={onResend}
      variant="body2"
      sx={({ palette }) => ({ cursor: 'pointer', color: palette.primary.main })}
    >
      Resend the code
    </Typography>
  );

const BackButton = ({ href }: { href: string }) => (
  <IconButton
    LinkComponent={Link}
    href={href}
    sx={{ justifyContent: 'center' }}
  >
    <BackIcon
      color="primary"
      sx={({ spacing }) => ({ marginLeft: spacing(0.5) })}
    />
  </IconButton>
);

const SubmitButton = () => {
  return (
    <Button variant="contained" fullWidth size="large" type="submit">
      Submit
    </Button>
  );
};

const FormLayout = (props: PropsWithChildren) => {
  return (
    <Paper sx={{ maxWidth: '442px', margin: '102px auto', padding: '40px' }}>
      <Box display="flex" justifyContent="center">
        <Box flex={1} justifyContent="center">
          <BackButton href="/auth/forgot-password" />
        </Box>
        <Typography
          variant="h5"
          textAlign="center"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          Reset your password
        </Typography>
        <Box flex={1} />
      </Box>
      <Box mt="24px">{props.children}</Box>
    </Paper>
  );
};

type ChangePasswordFormProps = {
  onSuccess: (result: unknown) => void;
  onAmplifyError: ({ error }: { error: AmplifyError }) => void;
  onError?: (error: unknown) => void;
};

export const ChangePasswordForm = (props: ChangePasswordFormProps) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = decodeURIComponent(queryParams.get('email')!);

  const { codeResend, handleResend } = useCodeResend({ email });

  const { formBag, onSubmit } = useForgotPasswordForm({
    email,
    onSuccess: props.onSuccess,
    onAmplifyFailure: props.onAmplifyError,
    onFailure: props.onError,
  });

  return (
    <FormLayout>
      <FormProvider {...formBag}>
        <form onSubmit={onSubmit}>
          <Stack spacing={2}>
            <TextField name="password" label="New password" type="password" />
            <TextField name="code" label="Confirmation code" type="text" />
            <CodeResendButton onResend={handleResend} codeResend={codeResend} />
            <SubmitButton />
          </Stack>
        </form>
      </FormProvider>
    </FormLayout>
  );
};
