import { useNavigate } from 'react-router';

import { SignUpForm } from './SignUpForm';

export const SignUp = () => {
  const navigate = useNavigate();

  return (
    <SignUpForm
      onSuccess={({ email }) => {
        const encodedEmail = encodeURIComponent(email);
        navigate(`/auth/sign-up-confirm?email=${encodedEmail}`);
      }}
      onError={(error) => {
        console.error(error);
      }}
    />
  );
};
